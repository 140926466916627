<template>
  <div class="bg-silver">
    <div class="t-breadcrumb">
      <router-link to="/home">{{ $t("title.main") }}</router-link> >
      <router-link to="/home/news">{{ $t("title.news") }}</router-link> >
      {{ news.title }}
    </div>

    <div class="form-container px-5 pb-13 pt-9" v-if="news">
      <div class="shadow-smoke bg-shade--gray rounded-10 px-5">
        <div class=" --c-prm pt-3">{{ news.showFromDate | dotDate }}</div>
        <div class="text-title font-weight-bold --c-prm pb-4">
          {{ news.title }}
        </div>
        <div class="border-top-main --border-smoke pb-9"></div>
        <v-img
          v-if="news.imageUrl"
          height="200"
          width="206"
          :src="news.imageUrl"
          :lazy-src="lazyImgUrl"
          class="mx-auto"
          alt="tokyu_vacations_news"
        />
        <div class="text-medium --medium-gray mt-9 pb-9 word-break">
          <format-span v-if="news" :value="news.body" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { lazyImageMixin } from '@/mixins/lazy-image.mixin'

export default {
  name: 'NewsDetail',
  mixins: [lazyImageMixin],
  data () {
    return {
      id: parseInt(window.location.pathname.slice(11))
    }
  },
  computed: {
    news () {
      return this.$store.state.notifications.new
    }
  },
  methods: {
    next (id) {
      if (window.location.pathname !== `/home/news/${id}`) {
        this.$router.push(`/home/news/${id}`)
      }
    },
    prev (id) {
      if (window.location.pathname !== `/home/news/${id}`) {
        this.$router.push(`/home/news/${id}`)
      }
    }
  },
  async mounted () {
    await this.$doLoading(async () => {
      await this.$showGqlError(async () => {
        await this.$store.dispatch('myWebNotificationList', {
          apollo: this.$apollo,
          take: 10,
          skip: 0
        })

        this.$store.commit('setNew', {
          state: this.$store.state,
          id: parseInt(window.location.pathname.slice(11))
        })
      })
    })
  }
}
</script>

<style lang="scss" scoped>
  .shadow-smoke {
    max-width: 500px;
  }
</style>
