export const lazyImageMixin = {
  methods: {
    getImage (imgUrl, isReservationImage = false) {
      if (!imgUrl) return isReservationImage ? this.lazyReservationImgUrl : this.lazyImgUrl
      return imgUrl
    }
  },
  data () {
    return {
      lazyImgUrl: require('@/assets/images/lazy-img.png'),
      lazyReservationImgUrl: require('@/assets/images/tokyu-vacation-reservation.jpg')
    }
  }
}
